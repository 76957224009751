<template>
  <section class="catalogs-list">
    <b-card>
      <div class="p-2 d-flex align-items-center justify-content-between">
        <h1 class="m-0 page-title">{{ $t("catalogs.catalogs") }}</h1>
        <div class="d-flex">
          <b-button
            v-if="globalPermission.products"
            variant="outline-primary"
            class="mx-2 py-0 d-flex align-items-center justify-content-center"
            :href="this.BackBaseUrl + 'web/export-catalogs'"
          >
            <ExportIcon  /> &nbsp;
            {{ $t("catalogs.export_catalogs") }}
          </b-button>
          <div
            class="d-flex align-items-center"
            v-if="globalPermission.catalogs && globalPermission.catalogs.store"
          >
            <b-link
              to="/catalog/add-catalog"
              role="button"
              class="btn btn-primary"
            >
              <feather-icon icon="PlusIcon" class="text-white" size="16" />
              {{ $t("catalogs.add_catalog") }}
            </b-link>
          </div>
        </div>
      </div>

      <form @submit.prevent>
        <b-row class="my-1 px-2" style="gap: 10px">
          <b-col cols="12" sm="auto">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search_placeholder')"
            />
          </b-col>
          <b-col>
            <b-button
              variant="outline-secondary"
              @click="clearSearch"
              class="mx-1"
              v-if="searchTerm"
            >
              {{ $t("clear_search") }}
            </b-button>
            <b-button @click="searchHandler" variant="primary" type="submit">
              {{ $t("search") }}
            </b-button>
          </b-col>
        </b-row>
      </form>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'main_image'" class="text-nowrap">
            {{ $t("g.main_image") }}
          </span>
          <span v-else-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span
            v-else-if="props.column.label === 'category'"
            class="text-nowrap"
          >
            {{ $t("g.category") }}
          </span>
          <span v-else-if="props.column.label === 'brand'" class="text-nowrap">
            {{ $t("g.brand") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'main_image'" class="text-nowrap">
            <b-avatar variant="info" :src="props.row.main_image" />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'category'"
            class="text-nowrap custom-cat"
          >
            <b-form-group label-for="Category">
              <b-form-select
                v-model="props.row.category_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="category_options"
                value-field="id"
                text-field="name"
                @change="
                  handleChangeCategory(props.row.slug, props.row.category_id)
                "
              />
            </b-form-group>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'action' &&
              globalPermission.catalogs &&
              (globalPermission.catalogs.update ||
                globalPermission.catalogs.destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission.catalogs &&
                    globalPermission.catalogs.update
                  "
                  :to="`/catalog/edit-catalog/${props.row.slug}`"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.catalogs &&
                    globalPermission.catalogs.destroy
                  "
                  @click="deleteCatalog(props.row.slug)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="mt-2 d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                size="md"
                align="center"
                v-model="currentPage"
                :total-rows="dataMeta.total"
                :per-page="dataMeta.per_page"
              >
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </section>
</template>

<script>
import {
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BLink,
  BAvatar,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import ExportIcon from "@/components/shared/ExportIcon";

export default {
  name: "catalogs",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    ExportIcon
  },

  props: {
    tableBody: {
      type: Array,
      default: () => [],
    },
    dataMeta: {
      type: Object,
    },
  },
  data() {
    return {
      page_data: null,
      searchTerm: "",
      currentPage: 1,
      overlayLoading: false,
      pageLength: 12,
      category_options: [],
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "main_image",
          field: "main_image",
          sortable: false,
        },
        {
          label: "name",
          field: "catalog",
          sortable: false,
        },
        {
          label: "category",
          field: "category",
          sortable: false,
        },
        {
          label: "brand",
          field: "brand",
          sortable: false,
        },
        {
          label: "action",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.tableBody.map((item, index) => {
      item.index = 12 * (this.currentPage - 1) + index + 1;
    });
    if (
      this.globalPermission.catalogs &&
      !(
        this.globalPermission.catalogs.update ||
        this.globalPermission.catalogs.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => "action" !== ele.label
      );
    }
    this.getCategories();
  },
  watch: {
    currentPage(newValue) {
      this.$emit("updatePage", newValue, this.searchTerm);
    },
    tableBody() {
      this.tableBody.map((item, index) => {
        item.index = 12 * (this.currentPage - 1) + index + 1;
      });
    },
  },
  computed: {
    BackBaseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  methods: {
    async getCategories() {
      try {
        const res = await this.$http.get("admin/categories");
        if (res.status === 200 || res.status === 201) {
          this.category_options = [];
          res.data.data.map((el) => {
            if (el.parent_id) {
              this.category_options.push({
                id: el.id,
                name: el.name,
              });
            }
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async handleChangeCategory(catalog_slug, category_id) {
      try {
        const res = await this.$http.post(
          `admin/catalogs/${catalog_slug}?_method=put`,
          {
            category_id: category_id,
          }
        );
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast(
            "success",
            res.data.message,
            res.data.message
          );
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async deleteCatalog(catalogSlug) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeleteCatalog(catalogSlug)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handleDeleteCatalog(catalogSlug) {
      this.$http
        .delete(`admin/catalogs/${catalogSlug}`)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast("success", res.data.message);
            this.$emit("updatePage", this.currentPage);
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    searchHandler() {
      this.$emit("searchForCatalog", this.searchTerm);
    },
    clearSearch() {
      this.searchTerm = "";
      this.$emit("searchForCatalog", "");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.custom-cat {
  display: flex;
  justify-content: center;
  .form-group {
    margin-bottom: 0;
  }
}
</style>
