var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"catalogs-list"},[_c('b-card',[_c('div',{staticClass:"p-2 d-flex align-items-center justify-content-between"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("catalogs.catalogs")))]),_c('div',{staticClass:"d-flex"},[(_vm.globalPermission.products)?_c('b-button',{staticClass:"mx-2 py-0 d-flex align-items-center justify-content-center",attrs:{"variant":"outline-primary","href":this.BackBaseUrl + 'web/export-catalogs'}},[_c('ExportIcon'),_vm._v("   "+_vm._s(_vm.$t("catalogs.export_catalogs"))+" ")],1):_vm._e(),(_vm.globalPermission.catalogs && _vm.globalPermission.catalogs.store)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-link',{staticClass:"btn btn-primary",attrs:{"to":"/catalog/add-catalog","role":"button"}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("catalogs.add_catalog"))+" ")],1)],1):_vm._e()],1)]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"my-1 px-2",staticStyle:{"gap":"10px"}},[_c('b-col',{attrs:{"cols":"12","sm":"auto"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search_placeholder')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('b-col',[(_vm.searchTerm)?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.clearSearch}},[_vm._v(" "+_vm._s(_vm.$t("clear_search"))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.searchHandler}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'main_image')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.main_image"))+" ")]):(props.column.label === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.name"))+" ")]):(props.column.label === 'category')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.category"))+" ")]):(props.column.label === 'brand')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.brand"))+" ")]):(props.column.label === 'action')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'main_image')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{attrs:{"variant":"info","src":props.row.main_image}})],1):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'category')?_c('span',{staticClass:"text-nowrap custom-cat"},[_c('b-form-group',{attrs:{"label-for":"Category"}},[_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.category_options,"value-field":"id","text-field":"name"},on:{"change":function($event){return _vm.handleChangeCategory(props.row.slug, props.row.category_id)}},model:{value:(props.row.category_id),callback:function ($$v) {_vm.$set(props.row, "category_id", $$v)},expression:"props.row.category_id"}})],1)],1):(
            props.column.field === 'action' &&
            _vm.globalPermission.catalogs &&
            (_vm.globalPermission.catalogs.update ||
              _vm.globalPermission.catalogs.destroy)
          )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
                  _vm.globalPermission.catalogs &&
                  _vm.globalPermission.catalogs.update
                )?_c('b-dropdown-item',{attrs:{"to":("/catalog/edit-catalog/" + (props.row.slug))}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(
                  _vm.globalPermission.catalogs &&
                  _vm.globalPermission.catalogs.destroy
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteCatalog(props.row.slug)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])]),_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"mt-2 d-flex justify-content-center flex-wrap"},[_c('div',[_c('b-pagination',{attrs:{"size":"md","align":"center","total-rows":_vm.dataMeta.total,"per-page":_vm.dataMeta.per_page},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }