<template>
  <b-card class="import-catalogs">
    <div slot="header" class="header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z"
          fill="#2B79AD"
        />
      </svg>
      <h5>{{ $t("importCatalogs.head") }}</h5>
    </div>
    <div slot="default" class="content-wrapper">
      {{ $t("importCatalogs.content") }}
    </div>
    <div slot="footer" class="footer">
      <b-button
        variant="primary"
        :href="`${baseUrl}` + 'web/export-catalogs'"
        target="_blank"
      >
        {{ $t("importCatalogs.downloadTemplate") }}
      </b-button>
      <b-button
        @click="$bvModal.show('UploadFileFormComponent')"
        variant="link"
      >
        {{ $t("importCatalogs.uploadSheet") }}
      </b-button>
    </div>
    <upload-file-form-component @reset="$emit('reset-page')" />
  </b-card>
</template>
<script>
import { BCard, BButton } from "bootstrap-vue";
import uploadFileFormComponent from "./uploadFileFormComponent";
export default {
  name: "ImportCatalogsComponent",
  components: {
    BCard,
    BButton,
    uploadFileFormComponent,
  },
  data() {
    return {
      templateLink: null,
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
