<template>
  <b-modal
    no-close-on-backdrop
    centered
    modal-class="UploadFileFormComponent"
    id="UploadFileFormComponent"
    :title="$t('importCatalogs.form.title')"
  >
    <validation-observer ref="from">
      <b-form>
        <b-row>
          <b-col cols="12" class="mb-1">
            <validation-provider
              #default="{ errors }"
              name="barnd"
              rules="required"
            >
              <span>
                {{ $t("importCatalogs.form.brand") }}
              </span>
              <b-form-select
                v-model="brand_id"
                :options="brands"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option :value="null" slot="first" disabled>{{
                  $t("importCatalogs.form.chooseBrand")
                }}</b-form-select-option>
              </b-form-select>
              <small class="text-danger">
                {{ errors ? errors[0] : null }}
              </small>
            </validation-provider> </b-col
          ><b-col cols="12" class="mb-1">
            <validation-provider
              #default="{ errors }"
              name="categories"
              rules="required"
            >
              <span>
                {{ $t("importCatalogs.form.categories") }}
              </span>
              <b-form-select
                v-model="category_id"
                :options="categories"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option :value="null" slot="first" disabled>{{
                  $t("importCatalogs.form.chooseCategory")
                }}</b-form-select-option>
              </b-form-select>
              <small class="text-danger">
                {{ errors ? errors[0] : null }}
              </small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mb-1" v-if="globalPermission.products.import">
            <validation-provider #default="{ errors }" name="isProduct">
              <b-form-checkbox
                id="checkbox-1"
                v-model="isProduct"
                name="checkbox-1"
                :value="true"
              >
                {{ $t("importCatalogs.form.importAsProduct") }}
              </b-form-checkbox>
              <small class="text-danger">
                {{ errors && errors[0] }}
              </small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mb-1" v-if="isProduct">
            <validation-provider #default="{ errors }" name="vendors">
              <span>
                {{ $t("importProducts.form.vendors") }}
              </span>
              <multiselect
                v-model="vendors_ids"
                :tag-placeholder="$t('importProducts.form.chooseVendors')"
                :placeholder="$t('importProducts.form.chooseVendors')"
                label="text"
                track-by="text"
                :options="vendors"
                :multiple="true"
                :taggable="true"
              ></multiselect>
              <small class="text-danger">
                {{ errors ? errors[0] : null }}
              </small>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-1">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-form-file
                v-model="file"
                accept=".xls, .xlsx"
                plain
                hidden
                ref="fileRef"
                name="fileRef"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">
                {{ errors ? errors[0] : null }}
              </small>
            </validation-provider>
            <button
              class="uploadBox"
              v-if="!file"
              @click.prevent="$refs.fileRef.$el.click()"
            >
              <span class="ico-wrapper">
                <img :src="icon" alt="import-icon" />
                <span>
                  {{ $t("importCatalogs.form.importUrFile") }}
                </span>
              </span>
            </button>
            <div class="filePreview" v-else>
              <div class="file">
                <div class="data">
                  <img :src="excelIco" alt="excel-ico" />
                  <p>{{ file.name }}</p>
                </div>
                <div class="del-ico">
                  <feather-icon
                    icon="Trash2Icon"
                    @click="() => (file = null)"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div slot="modal-footer" class="footer">
      <b-button :disabled="isBusy" @click="submit" variant="primary">
        {{ $t("importCatalogs.form.save") }} </b-button
      ><b-button variant="link" @click="cancel">
        {{ $t("importCatalogs.form.cancel") }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BButton,
  BFormFile,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "@validations";
import Multiselect from "vue-multiselect";
export default {
  name: "UploadFileFormComponent",
  data() {
    return {
      file: null,
      icon: require("@/assets/images/icons/importIcon.svg"),
      excelIco: require("@/assets/images/icons/excalIco.jpeg"),
      brands: [],
      categories: [],
      vendors: [],
      vendors_ids: [],
      isProduct: false,
      brand_id: null,
      category_id: null,
      isBusy: false,
    };
  },
  components: {
    Multiselect,
    BFormInput,
    BModal,
    BButton,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BRow,
    BCol,
    required,
    email,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
  },
  beforeMount() {
    this.getFormData();
  },
  methods: {
    async submit() {
      try {
        this.isBusy = true;
        const validation = await this.$refs.from.validate();
        if (validation) {
          const formData = await this.collectData();

          const res = await this.$http.post("admin/import-catalog", formData);
          if ([200, 201].includes(res.status)) {
            this.resetData();
            await this.$emit("reset");
            this.$helpers.makeToast("success", res.data.message);
            this.$bvModal.hide("UploadFileFormComponent");
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    collectData() {
      const formData = new FormData();
      formData.append("brand_id", this.brand_id);
      formData.append("category_id", this.category_id);
      formData.append("excel_file", this.file);
      formData.append("is_product", Number(this.isProduct));
      if (this.isProduct) {
        for (const item in this.vendors_ids) {
          formData.append(`vendor_ids[${item}]`, this.vendors_ids[item].value);
        }
      }
      return formData;
    },
    async getFormData() {
      try {
        const [categoriesResponse, brandsResponse, vendorsResponse] =
          await Promise.all([
            this.$http.get("admin/categories"),
            this.$http.get("admin/brands"),
            this.$http.get("admin/vendors?approved=true"),
          ]);
        if (
          [200, 201].includes(
            categoriesResponse.status &&
              brandsResponse.status &&
              vendorsResponse.status
          )
        ) {
          for (const brand of brandsResponse.data.data) {
            this.brands.push({ value: brand.id, text: brand.name });
          }
          for (const category of categoriesResponse.data.data) {
            if (category.is_leaf)
              this.categories.push({ value: category.id, text: category.name });
          }
          for (const vendor of vendorsResponse.data.data) {
            this.vendors.push({ value: vendor.id, text: vendor.store_name });
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    cancel() {
      this.resetData();
      this.$bvModal.hide("UploadFileFormComponent");
    },
    resetData() {
      const dataWillBeNull = ["file", "brand_id", "category_id"];
      dataWillBeNull.forEach((ele) => {
        this[ele] = null;
      });
      this.vendors_ids.length = 0;
      this.isProduct = false;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.UploadFileFormComponent {
  .modal-footer {
    border-top: 0px solid transparent;
    .footer {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .modal-header {
    border-bottom: 0px solid transparent;
    background-color: #fff;
  }
  .uploadBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    border: dashed 1px black;
    border-radius: 15px;
    .ico-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 50px;
        width: 50px;
      }
    }
  }
  .filePreview {
    .file {
      display: flex;
      justify-content: space-between;
      .data {
        display: flex;
        gap: 10px;
        img {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}
</style>
