<template>
  <Loading v-if="isLoading" />
  <main v-else>
    <b-form-group v-slot="{ ariaDescribedby }" class="choose-option">
      <b-form-radio
        v-model="is_request"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="true"
        >{{ $t("catalogs.catalogs") }}</b-form-radio
      >
      <b-form-radio
        class="second-radio"
        v-model="is_request"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="false"
        >{{ $t("catalogs.catalog_requests") }}</b-form-radio
      >
      <b-badge v-if="newRequest" variant="danger">{{ newRequest }}</b-badge>
    </b-form-group>
    <import-catalogs-component
      v-if="globalPermission.catalogs && globalPermission.catalogs.import"
      v-show="is_request"
    />
    <catalogs
      @searchForCatalog="searchForCatalog"
      v-show="is_request"
      :tableBody="catalog"
      :dataMeta="catalogMeta"
      @updatePage="getPageCatalog"
    />
    <catalog-request
      @searchForCatalog="searchForCatalogRequest"
      v-show="!is_request"
      :tableBody="catalogRequest"
      :dataMeta="catalogRequestMeta"
      @updatePage="getPageCatalogRequest"
    />
    <overlayLoading :isLoading="overlayLoading" />
  </main>
</template>

<script>
import { BFormGroup, BFormRadio, BBadge } from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import CatalogRequest from "@/components/catalogs/list/catalogRequest.vue";
import Catalogs from "@/components/catalogs/list/catalogs.vue";
import overlayLoading from "@/components/shared/OverlayComponent/index.vue";
import ImportCatalogsComponent from "@/components/catalogs/list/ImportCatalogsComponent/index.vue";

export default {
  name: "addCatalogs",
  components: {
    ImportCatalogsComponent,
    CatalogRequest,
    Catalogs,
    Loading,
    BFormGroup,
    BFormRadio,
    BBadge,
    overlayLoading,
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      catalogRequest: [],
      catalog: [],
      overlayLoading: false,
      tableBody: [],
      is_request: true,
      newRequest: 0,
    };
  },
  beforeMount() {
    this.getCatalogsList();
  },
  methods: {
    async fetchData(url, pageNumber, search) {
      try {
        const res = search
          ? await this.$http.get(url, {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: pageNumber,
                search,
              },
            })
          : await this.$http.get(url, {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: pageNumber,
              },
            });
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCatalogsList() {
      try {
        this.isLoading = true;
        const [catalog, catalogRequest] = await Promise.all([
          this.fetchData("admin/catalogs?is_approved=true", 1),
          this.fetchData("admin/catalogs?is_approved=false", 1),
        ]);

        this.newRequest = catalogRequest.meta.total;
        this.catalogMeta = catalog.meta;
        this.catalogRequestMeta = catalogRequest.meta;

        catalog.data.map((item) => {
          this.catalog.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            category_id: item?.category.id,
            slug: item.slug,
          });
        });
        catalogRequest.data.map((item) => {
          this.catalogRequest.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            category_id: item?.category.id,
            slug: item.slug,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getPageCatalog(pageNumber, search) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "admin/catalogs?is_approved=true",
          pageNumber,
          search
        );
        this.catalog = [];
        res.data.map((item) => {
          this.catalog.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            category_id: item?.category.id,
            slug: item.slug,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getPageCatalogRequest(pageNumber, search) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "admin/catalogs?is_approved=false",
          pageNumber,
          search
        );
        this.catalogRequest = [];
        res.data.map((item) => {
          this.catalogRequest.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            slug: item.slug,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    update() {
      this.getCatalogsList();
    },
    async searchForCatalog(searchData) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "admin/catalogs?is_approved=true",
          null,
          searchData
        );
        this.catalogMeta = res.meta;
        this.catalog = [];
        res.data.map((item) => {
          this.catalog.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            slug: item.slug,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async searchForCatalogRequest(searchData) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "admin/catalogs?is_approved=false",
          null,
          searchData
        );
        this.catalogRequestMeta = res.meta;
        this.catalogRequest = [];
        res.data.map((item) => {
          this.catalogRequest.push({
            id: item.id,
            country: item?.made_in,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item.main_image?.path,
            vendor: item?.vendor_name,
            category: item?.category.name,
            slug: item.slug,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
