var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","modal-class":"UploadFileFormComponent","id":"UploadFileFormComponent","title":_vm.$t('importCatalogs.form.title')}},[_c('validation-observer',{ref:"from"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"barnd","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.brand"))+" ")]),_c('b-form-select',{attrs:{"options":_vm.brands,"state":errors.length > 0 ? false : null},model:{value:(_vm.brand_id),callback:function ($$v) {_vm.brand_id=$$v},expression:"brand_id"}},[_c('b-form-select-option',{attrs:{"slot":"first","value":null,"disabled":""},slot:"first"},[_vm._v(_vm._s(_vm.$t("importCatalogs.form.chooseBrand")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors ? errors[0] : null)+" ")])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.categories"))+" ")]),_c('b-form-select',{attrs:{"options":_vm.categories,"state":errors.length > 0 ? false : null},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}},[_c('b-form-select-option',{attrs:{"slot":"first","value":null,"disabled":""},slot:"first"},[_vm._v(_vm._s(_vm.$t("importCatalogs.form.chooseCategory")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors ? errors[0] : null)+" ")])]}}])})],1),(_vm.globalPermission.products.import)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"isProduct"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true},model:{value:(_vm.isProduct),callback:function ($$v) {_vm.isProduct=$$v},expression:"isProduct"}},[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.importAsProduct"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors && errors[0])+" ")])]}}],null,false,2945930440)})],1):_vm._e(),(_vm.isProduct)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"vendors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("importProducts.form.vendors"))+" ")]),_c('multiselect',{attrs:{"tag-placeholder":_vm.$t('importProducts.form.chooseVendors'),"placeholder":_vm.$t('importProducts.form.chooseVendors'),"label":"text","track-by":"text","options":_vm.vendors,"multiple":true,"taggable":true},model:{value:(_vm.vendors_ids),callback:function ($$v) {_vm.vendors_ids=$$v},expression:"vendors_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors ? errors[0] : null)+" ")])]}}],null,false,4212166078)})],1):_vm._e(),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"fileRef",attrs:{"accept":".xls, .xlsx","plain":"","hidden":"","name":"fileRef","state":errors.length > 0 ? false : null},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors ? errors[0] : null)+" ")])]}}])}),(!_vm.file)?_c('button',{staticClass:"uploadBox",on:{"click":function($event){$event.preventDefault();return _vm.$refs.fileRef.$el.click()}}},[_c('span',{staticClass:"ico-wrapper"},[_c('img',{attrs:{"src":_vm.icon,"alt":"import-icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.importUrFile"))+" ")])])]):_c('div',{staticClass:"filePreview"},[_c('div',{staticClass:"file"},[_c('div',{staticClass:"data"},[_c('img',{attrs:{"src":_vm.excelIco,"alt":"excel-ico"}}),_c('p',[_vm._v(_vm._s(_vm.file.name))])]),_c('div',{staticClass:"del-ico"},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"},on:{"click":function () { return (_vm.file = null); }}})],1)])])],1)],1)],1)],1),_c('div',{staticClass:"footer",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('b-button',{attrs:{"disabled":_vm.isBusy,"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.save"))+" ")]),_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("importCatalogs.form.cancel"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }