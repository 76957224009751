<template>
  <section class="catalogs-list">
    <b-card>
      <div class="p-2 d-flex align-items-center justify-content-between">
        <h2 class="m-0 page-title">{{ $t("catalogs.catalog_requests") }}</h2>
      </div>

      <form @submit.prevent>
        <b-row class="my-1 px-2" style="gap: 10px">
          <b-col cols="12" sm="auto">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search_placeholder')"
            />
          </b-col>
          <b-col>
            <b-button
              variant="outline-secondary"
              @click="clearSearch"
              class="mx-1"
              v-if="searchTerm"
            >
              {{ $t("clear_search") }}
            </b-button>
            <b-button @click="searchHandler" variant="primary" type="submit">
              {{ $t("search") }}
            </b-button>
          </b-col>
        </b-row>
      </form>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :sort-options="{
          enabled: true,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'main_image'" class="text-nowrap">
            {{ $t("g.main_image") }}
          </span>
          <span v-else-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span
            v-else-if="props.column.label === 'category'"
            class="text-nowrap"
          >
            {{ $t("g.category") }}
          </span>
          <span v-else-if="props.column.label === 'vendor'" class="text-nowrap">
            {{ $t("g.vendor") }}
          </span>
          <span v-else-if="props.column.label === 'brand'" class="text-nowrap">
            {{ $t("g.brand") }}
          </span>
          <span
            v-else-if="
              props.column.label === 'details' &&
              globalPermission.catalogs &&
              !globalPermission.catalogs.store
            "
            class="text-nowrap"
          >
            {{ $t("g.details") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'main_image'" class="text-nowrap">
            <b-avatar variant="info" :src="props.row.main_image" />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'category'"
            class="text-nowrap"
          >
            {{ props.row.category }}
          </span>

          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            {{ props.row.vendor }}
          </span>

          <!-- Column: details -->
          <span
            v-else-if="
              props.column.field === 'details' &&
              globalPermission.catalogs &&
              globalPermission.catalogs.store
            "
          >
            <b-link :to="`/catalog/request-catalog/${props.row.slug}`">
              {{ $t("g.view") }}</b-link
            >
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="mt-2 d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                size="md"
                align="center"
                v-model="currentPage"
                :total-rows="dataMeta.total"
                :per-page="dataMeta.per_page"
              >
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </section>
</template>

<script>
import {
  BPagination,
  BCard,
  BLink,
  BAvatar,
  BButton,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "catalogs",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BAvatar,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormInput,
  },

  props: {
    tableBody: {
      type: Array,
      default: () => [],
    },
    dataMeta: {
      type: Object,
    },
  },
  mounted() {
    this.tableBody.map((item, index) => {
      item.index = 12 * (this.currentPage - 1) + index + 1;
    });
    if (
      this.globalPermission.catalogs &&
      !this.globalPermission.catalogs.store
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => "details" !== ele.label
      );
    }
  },
  watch: {
    currentPage(newValue) {
      this.$emit("updatePage", newValue, this.searchTerm);
    },
    tableBody() {
      this.tableBody.map((item, index) => {
        item.index = 12 * (this.currentPage - 1) + index + 1;
      });
    },
  },
  data() {
    return {
      searchTerm: "",
      currentPage: 1,
      page_data: null,
      overlayLoading: false,
      pageLength: 20,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "main_image",
          field: "main_image",
          sortable: false,
        },
        {
          label: "name",
          field: "catalog",
        },
        {
          label: "vendor",
          field: "vendor",
          sortable: false,
        },
        {
          label: "category",
          field: "category",
          sortable: false,
        },
        {
          label: "brand",
          field: "brand",
          sortable: false,
        },
        {
          label: "details",
          field: "details",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    searchHandler() {
      this.$emit("searchForCatalog", this.searchTerm);
    },
    clearSearch() {
      this.searchTerm = "";
      this.$emit("searchForCatalog", "");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
